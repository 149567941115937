// Pinia
import { defineStore } from 'pinia'

// Common
import { stripHTMLTags, sanitizeString } from '@/common/utils/characters'
import { RESULTS_PER_PAGE_NUMBERS } from '@/common/static/search'

// Types
import { QueryStyle, ResultsObjectInterface } from '@/types/search/searchTypes'
import { knowledgeBaseStoreInterface, SupportArticleInterface } from '~/types/support/knowledgeBaseTypes'

export const useKnowledgeBaseStore = defineStore({
  id: 'knowledge-base',

  // state
  state: (): knowledgeBaseStoreInterface => ({
    searchInput: '',
    typedSearchInput: '',
    queryStyle: QueryStyle.NAVIGATION,
    results: {},
    page: {
      resultsPerPage: RESULTS_PER_PAGE_NUMBERS[0],
      currentPage: 1,
      responseTime: 0,
    },
    typeahead: {
      storedSearchInput: '',
      selectedIndex: 0,
      results: {},
    },
    ui: {
      loading: false,
      formFeedback: {
        showFeedback: false,
        showDescription: false,
        errorMessage: '',
        succesMessage: '',
      },
    },
    voteRecordId: null,
    knowledgebaseErrors: [],
  }),
  // actions
  actions: {
    setSearchInput(val: string) {
      // normalize for japanse language input
      this.searchInput = sanitizeString(stripHTMLTags(decodeURIComponent(val))).normalize('NFKC')
    },
    setTypedSearchInput(val: string) {
      this.typedSearchInput = val
    },
    setQueryStyle(val: QueryStyle) {
      this.queryStyle = val
    },
    setResults(val: ResultsObjectInterface) {
      this.results = val
    },
    setTypeaheadResults(val: ResultsObjectInterface) {
      this.typeahead.results = val
    },
    clearResults(type: string) {
      if (type === 'search') {
        this.results = {}
      } else if (type === 'typeahead') {
        this.typeahead.results = {}
      }
    },
    setresultsPerPage(val: number) {
      this.page.resultsPerPage = val
    },
    setCurrentPage(val: number) {
      this.page.currentPage = val
    },
    setResponseTime(val: number) {
      this.page.responseTime = val
    },
    setFormFeedback(val: {
      showFeedback: boolean
      errorMessage?: string
      succesMessage?: string
      showDescription: boolean
    }) {
      this.ui.formFeedback.showFeedback = val.showFeedback
      this.ui.formFeedback.showDescription = val.showDescription
      this.ui.formFeedback.errorMessage = val.errorMessage || ''
      this.ui.formFeedback.succesMessage = val.succesMessage || ''
    },
    setVoteRecordId(val: string | null) {
      this.voteRecordId = val
    },
    setLoading(val: boolean) {
      this.ui.loading = val
    },
    clearErrors() {
      this.knowledgebaseErrors = []
    },
  },
  // getters
  getters: {
    getSearchCount(): number {
      return this.results.meta?.page?.total_results || 0
    },
    getResultFromArticleNumber:
      (state) =>
      (articlenumber: string): SupportArticleInterface | null => {
        return state.results.results?.find((result) => result.articlenumber.raw === articlenumber) || null
      },
  },
})
