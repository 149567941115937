import { RetryLink } from '@apollo/client/link/retry'
import { HttpLink } from '@apollo/client/link/http'
import { onError } from '@apollo/client/link/error'
import { ApolloLink } from '@apollo/client/link/core'
import { Observable } from '@apollo/client/utilities'
import { ASTNode, stripIgnoredCharacters } from 'graphql'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { v4 as uuid } from 'uuid'
import { detectServerBaseUrl, findTLD } from '@cstweb/common'
import introspectionQueryResultData from './types/fragmentTypes.json'
import { logger } from './logger'
import { TLD } from '@/common/constants/utils'

const logReqStartLink = new ApolloLink((operation, forward) => {
  const reqId = uuid()
  operation.setContext({ reqId })
  // const msg = `[request]: ${operation.operationName} [START]`
  const msg = `[request]: ${operation.operationName}`
  logger.debug({ reqId, operation: operation.operationName, variables: operation.variables }, msg)
  return forward(operation)
})

const logReqEndLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const { reqId } = operation.getContext()
    const msg = `[request]: ${operation.operationName} [FINISH]`
    logger.debug({ reqId }, msg)
    return data
  })
})

// Log any GraphQL errors or network error that occurred
const logReqErrorLink = ({ url: path, baseURL }: { url: string; baseURL: string }) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    const { reqId } = operation.getContext()
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        const query = operation.query.loc?.source.body
        const msg = `[graphQL error]: ${message} `
        logger.error(
          {
            reqId,
            path,
            baseURL,
            operation: operation.operationName,
            variables: operation.variables,
            query,
            locations,
            gqlPath: path,
          },
          msg
        )
      })
    }
    if (networkError) {
      logger.error({ reqId, path, baseURL, operation: operation.operationName }, `[Network error]: ${networkError}`)
    }
  })

const RESPONSE_HEADERS = [
  'x-runtime',
  'x-query-complexity',
  'x-resolver-cost',
  'x-reference-depth',
  'x-served-by',
  'x-cache',
  'x-cache-hits',
  'x-timer',
  'x-request-id',
  'age',
  'cf-cache-status',
  'cf-ray',
]

const metricsLink = ({ url: path, baseURL: url, branch }: { url: string; baseURL: string; branch?: string }) =>
  new ApolloLink((operation, forward) => {
    const startTime = new Date().getTime()
    const reqId = uuid()
    operation.setContext({ reqId })
    const observable = forward(operation)

    // Return a new observable so no other links can call .subscribe on the one that we were passsed.
    return new Observable((observer) => {
      observable.subscribe({
        complete: () => {
          const context = operation.getContext()

          const elapsed = new Date().getTime() - startTime

          const responseHeaders: {
            [key: string]: any
          } = {}

          if (context.response.headers) {
            // console.dir(context.response.headers)
            RESPONSE_HEADERS.forEach((header) => {
              if (context.response.headers.get(header)) {
                responseHeaders[header] = context.response.headers.get(header)
              }
            })
          }

          const msg = `[graphql query]: ${operation.operationName} - Total time: ${elapsed}ms`
          logger.debug(
            {
              lgroup: 'perf',
              path,
              url,
              operation: operation.operationName,
              variables: operation.variables,
              branch,
              response: { headers: responseHeaders },
              perf: { totalTime: `${elapsed}ms` },
            },
            msg
          )

          observer.complete()
        },
        next: observer.next.bind(observer),
        error: (error) => {
          observer.error(error)
        },
      })
    })
  })

export default function ({ $config, req, route }: { $config: any; req: any; route: any }) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  })

  let baseURL = $config.contentstack.apiHost
  let host

  const url = route.fullPath

  if (process.server) {
    host = process.static ? '' : detectServerBaseUrl(req)
  }

  if (process.client) {
    host = location.origin
    if (TLD.CN.toLowerCase() === findTLD(host)?.toLowerCase()) {
      baseURL = $config.contentstack.cn.apiHost || baseURL
    }
  }

  logger.debug({ baseURL, tld: findTLD(host) }, '[cms-client]: setup')

  return {
    defaultHttpLink: false,
    cache: new InMemoryCache({ fragmentMatcher }),
    link: ApolloLink.from([
      // logReqStartLink,
      new RetryLink({
        attempts: {
          max: 3,
        },
      }),
      // logReqEndLink,
      logReqErrorLink({ url, baseURL }),
      metricsLink({
        url,
        baseURL,
        branch: $config.contentstack.useBranches === 'true' ? `${$config.contentstack.branch}` : undefined,
      }),
      new HttpLink({
        uri: `${baseURL}/stacks/${$config.contentstack.apiKey}?environment=${$config.contentstack.env}`,
        headers: {
          access_token: `${$config.contentstack.deliveryToken}`,
          ...($config.contentstack.useBranches === 'true' && { branch: `${$config.contentstack.branch}` }),
        },
        // Minify GraphQL queries. By default, all queries are sent to the server in prettified form.
        // Query minification had to be added, because of the PDP template query.
        // It is too long and without minification, the server would return 413.
        print(ast: ASTNode, originalPrint: (ast: ASTNode) => string) {
          return stripIgnoredCharacters(originalPrint(ast))
        },
      }),
    ]),
  }
}
