import { useCountry } from '~/composables/country/useCountry'

export default function (ctx: any) {
  // Add the bot to US only
  const { isUS } = useCountry()
  if (!isUS.value) return

  // Check the routes for this chatbot
  const includePathArray = [
    '/applications/',
    '/learn-and-support/troubleshooting',
    '/learn-and-support/support-information',
    '/learn-and-support/technical-support',
    '/about-us/contact-us',
  ]
  const pdpRouteName = 'custom-products-id'

  // Check if this route is not in the include array or is not the PDP page
  if (!includePathArray.some((path) => ctx.route.path.includes(path) || ctx.route.name === pdpRouteName)) return

  // We passed the checks, let's load the chatbot
  const chatbotUrl = ctx.$config.salesforce.chatbotUrl
  const chatbotId = ctx.$config.salesforce.chatbotId
  const chatbotName = ctx.$config.salesforce.chatbotName
  const scrt2URL = ctx.$config.salesforce.chatbotScrt2URL

  // Method to initialize Embedded Messaging
  const initEmbeddedMessaging = () => {
    try {
      window.embeddedservice_bootstrap.settings.language = 'en_US' // For example, enter 'en' or 'en-US'
      window.embeddedservice_bootstrap.init(chatbotId, chatbotName, chatbotUrl, {
        scrt2URL,
      })
    } catch (err) {
      console.error('Error loading Embedded Messaging: ', err)
    }
  }

  // Add the script and callback to the head of the included pages and init
  ctx.app.head.script.push({
    src: `${chatbotUrl}/assets/js/bootstrap.min.js`,
    body: true,
    async: false,
    defer: false,
    vmid: 'embedded-messaging',
    callback: () => {
      initEmbeddedMessaging()
    },
  })
}
