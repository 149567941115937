













import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { NavigationItemFragmentFragment } from '~/modules/contentstack/types'
import ContentImage from '~/components/feature/static-content/components/ContentImage.vue'
export default defineComponent({
  components: {
    ContentImage,
  },
  props: {
    entity: {
      required: true,
      type: Object as PropType<
        NonNullable<
          NonNullable<NonNullable<NavigationItemFragmentFragment['entityConnection']>['edges']>[number]
        >['node']
      >,
    },
    link: {
      required: true,
      type: Object as PropType<
        NonNullable<
          NonNullable<NonNullable<NavigationItemFragmentFragment['navigation_linkConnection']>['edges']>[number]
        >['node']
      >,
    },
  },
  setup(props) {
    // for now the only entity that can be linked in navigation item is content image
    const isEntityImage = props.entity?.__typename === 'ContentImage'
    const clearedLinkV2 = props.link?.link_v2
    const openInNewWindow = props.link?.open_in_new_window
    return { isEntityImage, clearedLinkV2, openInNewWindow }
  },
})
