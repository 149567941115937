

































































import { defineComponent, ref } from '@nuxtjs/composition-api'

import Topbar from '@/components/global/topbar/Topbar.vue'
import Navigation from '@/components/global/navigation/Navigation.vue'
import Footer from '@/components/global/footer/Footer.vue'
import RenderDebug from '~/components/global/RenderDebug.vue'

import { useSidebarStore } from '@/store'

import LogMiddleware from '@/middleware/log'
import WithDataMiddleware from '@/middleware/with-data'

export default defineComponent({
  components: {
    Topbar,
    Navigation,
    Footer,
    RenderDebug,
  },
  middleware: [LogMiddleware, WithDataMiddleware],
  setup() {
    const { sidebar } = useSidebarStore()
    const showBannerAndNavbar = ref(true)
    const showSearch = ref(true)
    const showSearchOverlay = ref(false)

    const onToggleSearch = () => {
      showSearch.value = !showSearch.value
    }

    const onNavigationHover = (hoverIn: boolean) => {
      showSearchOverlay.value = hoverIn
    }

    return {
      sidebar,
      showBannerAndNavbar,
      showSearch,
      showSearchOverlay,
      onToggleSearch,
      onNavigationHover,
    }
  },
})
