

































import { defineComponent, ref } from '@nuxtjs/composition-api'
import IconFa6SolidXmark from '~icons/fa6-solid/xmark'

export default defineComponent({
  components: { IconFa6SolidXmark },
  props: {
    modalTitle: {
      type: String,
      default: '',
    },
  },
  setup() {
    const lightMode = ref(false)

    return { lightMode }
  },
})
