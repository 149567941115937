












import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    attrs: {
      type: Object as any,
      required: true,
    },
  },
  setup(props) {
    const url = props.attrs?.url ?? null
    const alt = props.attrs?.alt ?? null
    const caption = props.attrs?.caption ?? null
    const anchorLink = props.attrs?.anchorLink ?? null
    const className = props.attrs?.['class-name'] ?? null
    const width = props.attrs?.width ?? 'unset'

    const textAlign = props.attrs?.style?.['text-align'] ?? props.attrs?.style?.textAlign ?? null

    const imgStyles = computed(() => ({
      margin: textAlign === 'center' ? 'auto' : 'unset',
      float: ['left', 'right'].includes(textAlign) ? textAlign : 'unset',
      width: width ? width + 'px' : 'unset',
    }))

    return { url, alt, caption, anchorLink, className, imgStyles }
  },
})
