











import { defineComponent } from '@nuxtjs/composition-api'
import { ASSET_TYPES } from '~/common/constants/rte'
import DynamicSvg from '~/components/global/DynamicSvg.vue'
import { TreeNode } from '~/types/rte/rteTypes'

export default defineComponent({
  components: {
    DynamicSvg,
  },
  props: {
    node: {
      required: true,
      type: Object as () => TreeNode,
    },
  },
  setup(props) {
    // .. Default properties
    const id = props.node.attrs?.id ?? ''
    const className = props.node.attrs?.['class-name']
    const style = props.node.attrs?.inline

    // .. Asset related
    const assetType = props.node.attrs?.['asset-type']
    const assetAlt = props.node.attrs?.['asset-alt'] ?? ''
    const assetLink = props.node.attrs?.['asset-link']

    // .. Calculated
    const isSvg = assetType === ASSET_TYPES.IMAGE.SVG
    const isImage = Object.values(ASSET_TYPES.IMAGE).includes(assetType)

    return {
      id,
      className,
      style,
      assetAlt,
      assetLink,
      isSvg,
      isImage,
    }
  },
})
