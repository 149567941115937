import { DefaultNuxtLoading } from '@nuxt/types/app'
import { computed, ref, onMounted, nextTick, watchEffect } from '@nuxtjs/composition-api'

export function useLoading() {
  const nuxtLoading = ref<DefaultNuxtLoading>()

  onMounted(async () => {
    await nextTick()
    nuxtLoading.value = window.$nuxt.$loading as DefaultNuxtLoading
  })

  const loading = computed(() => nuxtLoading.value?.get() ?? 0)
  const isLoading = computed(() => loading.value !== 0)

  // watchEffect(() => {
  //   console.warn(loading.value)
  //   console.warn(isLoading.value)
  // })

  return { loading, isLoading }
}
