












































import { defineComponent, useContext } from '@nuxtjs/composition-api'
import JsonRteField from '@/components/global/cms/JsonRteField.vue'
import { useCMSGlobalSettingsStore, useCMSNavigationStore } from '@/store/cms'
import { DEVKEY } from '~/common/constants/cms'

import MenuHolder from '@/components/global/footer/MenuHolder.vue'
import { RteNode } from '~/types/rte/rteTypes'

export default defineComponent({
  components: {
    MenuHolder,
    JsonRteField,
  },
  setup() {
    const { getAttributeByCountry } = useCMSGlobalSettingsStore()
    const cmsNavigationStore = useCMSNavigationStore()
    const navigation = cmsNavigationStore.byKey(DEVKEY.GLOBAL_FOOTER)
    const brandLogo = getAttributeByCountry('footerBrandLogo')
    const social = getAttributeByCountry('social')
    const techSupportNumber = getAttributeByCountry('footerTechPhoneNumber')
    const ctx = useContext()

    const backgroundImageUrl = getAttributeByCountry('backgroundImageId')
      ? ctx.$img(`${getAttributeByCountry('backgroundImageId')}`, null, {
          provider: 'mc',
        })
      : ''

    const copyRightJson: RteNode | undefined = getAttributeByCountry('copyrightRte')

    return { brandLogo, copyRightJson, navigation, social, backgroundImageUrl, techSupportNumber }
  },
})
