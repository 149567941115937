// Pinia
import { defineStore } from 'pinia'

// Common
import isEmpty from 'lodash/fp/isEmpty'
import { categoriesOrder } from '@/common/static/facets'
import { SEARCH_TYPES, RESULTS_PER_PAGE_NUMBERS } from '@/common/static/search'
import { decodeHTMLEntities, highlightText, stripHTMLTags, sanitizeString } from '@/common/utils/characters'
import { getResourceHeading } from '@/common/utils/search'

// Mapping
import { sortMapping } from '@/common/mapping/mapping'

// Types
import {
  SearchStoreInterface,
  SuggestsObjectInterface,
  CheckedFacetsInterface,
  SearchResultsObjectInterface,
  SuggestionlistObjectInterface,
  TabCounInterface,
  SearchSuggestionsInterface,
  QueryStyle,
} from '@/types/search/searchTypes'

import { ElasticFieldsInterface } from '@/types/search/elasticTypes'

export const useSearchStore = defineStore({
  id: 'search',

  // state
  state: (): SearchStoreInterface => ({
    searchInput: '',
    results: {
      product: {},
      resources: {},
    },
    page: {
      resultsPerPage: RESULTS_PER_PAGE_NUMBERS[0],
      currentPage: 1,
      sortField: 'Relevance',
      responseTime: 0,
    },
    ui: {
      showLoader: false,
      showMobileFacets: false,
      showAllFacets: true,
      showSearching: false,
    },
    typeahead: {
      showSuggests: false,
      showHistoryOnly: true,
      suggestionList: [],
      redirect: true,
    },
    facets: {
      checkedFacets: [],
      cachedFacets: {},
      clickedMultiselectFacet: {
        facetname: '',
        facetdata: [],
      },
    },
    tabs: {
      selectedTab: SEARCH_TYPES.PRODUCT,
      tabCount: {
        product: 0,
        resources: 0,
      },
    },
    addToBasketProduct: {} as ElasticFieldsInterface,
    queryStyle: null,
    typedSearchQuery: '',
    storedSearchInput: '',
    storedTypeaheadSearchInput: '',
    // suggestions
    searchSuggestions: undefined,
    // errors
    searchErrors: [],
    // infoPanel
    infoPanelsOpen: false,
    infoPanelOpenIDs: [],
  }),

  // actions
  // Can i Make this more general?
  actions: {
    setFacetState(facetname: string) {
      if (this.facets.clickedMultiselectFacet.facetname === facetname) {
        return
      }

      if (this.results?.product?.facets !== undefined && this.results?.product?.facets[facetname] !== undefined) {
        this.facets.clickedMultiselectFacet = {
          facetname,
          facetdata: this.results?.product?.facets[facetname],
        }
      }
    },
    clearMultiselectFacetState() {
      this.facets.clickedMultiselectFacet = {
        facetname: '',
        facetdata: [],
      }
    },
    setShowAllFacets(boolean: boolean) {
      this.ui.showAllFacets = boolean
    },
    setTypeaheadRedirect(boolean: boolean) {
      this.typeahead.redirect = boolean
    },
    setTabCount(tabCount: TabCounInterface) {
      this.tabs.tabCount.resources = tabCount.resources
      this.tabs.tabCount.product = tabCount.product
    },
    setSelectedTab(tab: string) {
      if (tab !== SEARCH_TYPES.PRODUCT && tab !== SEARCH_TYPES.RESOURCE) {
        return
      }
      this.tabs.selectedTab = tab
    },
    setAddToBasketProduct(fields: any) {
      this.addToBasketProduct = fields
    },
    resetAddToBasketProduct() {
      this.addToBasketProduct = {} as ElasticFieldsInterface
    },
    setCurrentPage(nr: number) {
      if (isNaN(nr) || nr < 1) {
        return
      }
      this.page.currentPage = nr
    },
    setSortField(field: string) {
      // this.sortField = sortMapping[field as keyof typeof sortMapping]
      this.page.sortField = field
    },
    setResponseTime(time: number) {
      this.page.responseTime = time
    },
    setShowSearching(boolean: boolean) {
      this.ui.showSearching = boolean
    },
    setResults(payload: SearchResultsObjectInterface, tab: string = '') {
      // check if categories facet is present
      // if so, change the order to a custom order
      if (payload.facets !== undefined) {
        if (payload.facets.categories !== undefined) {
          payload?.facets?.categories[0]?.data?.sort(
            (a: any, b: any) => categoriesOrder.indexOf(a.value) - categoriesOrder.indexOf(b.value)
          )
        }
      }

      // check if we have a storedMultiSelect facet
      // if so, overwrite in payload
      const clickedFacetname = this.facets.clickedMultiselectFacet.facetname
      if (clickedFacetname !== undefined && payload.facets !== undefined) {
        payload.facets[clickedFacetname] = this.facets.clickedMultiselectFacet.facetdata
      }

      // set the results
      const tabToUse = tab.length > 0 ? tab : this.tabs.selectedTab

      // freeze the object to prevent reactivity in every el and improve performance
      this.results[tabToUse] = Object.freeze(payload)
    },
    setresultsPerPage(nr: number) {
      this.page.resultsPerPage = nr
    },
    setSearchSuggestions(suggestions: SearchSuggestionsInterface) {
      this.searchSuggestions = suggestions
    },
    reset() {
      this.results = {
        product: {},
        resources: {},
      }
      this.searchInput = ''
      this.ui.showLoader = false
      this.facets.checkedFacets = []
    },
    resetSuggests() {
      this.typeahead.showHistoryOnly = true
      this.typeahead.suggestionList = this.typeahead.suggestionList.filter((el: SuggestionlistObjectInterface) => {
        if (el.type !== SEARCH_TYPES.HISTORY) {
          return el
        }
        return false
      })
    },
    hideSuggests() {
      this.typeahead.showSuggests = false
    },
    setShowHistoryOnly(boolean: boolean) {
      this.typeahead.showHistoryOnly = boolean
    },
    setSuggests(payload: SuggestsObjectInterface) {
      // extract values to list to use for keydown support
      const payloadKeys = Object.keys(payload)
      const tempList: any = []

      payloadKeys
        .filter((el) => {
          if (payload[el].data === undefined || payload[el].data === null) {
            return false
          }

          if (payload[el].data !== undefined) {
            return el
          }

          if (payload[el].data.results !== null) {
            return el
          }
          return false
        })
        .forEach((payloadKey) => {
          // check if we can change the history layout
          if (payloadKey !== SEARCH_TYPES.HISTORY) {
            this.typeahead.showHistoryOnly = false
          }

          if (payload[payloadKey] !== undefined && payload[payloadKey] !== null) {
            if (payloadKey === SEARCH_TYPES.HISTORY) {
              Object.keys(payload[payloadKey].data).forEach((phrase: string, index: number) => {
                if (index === 0) {
                  // push header
                  tempList.push({
                    title: 'Search history',
                    type: SEARCH_TYPES.HISTORY,
                    selected: false,
                    header: true,
                  })
                }

                tempList.push({
                  title: decodeHTMLEntities(phrase),
                  type: SEARCH_TYPES.HISTORY,
                  selected: false,
                })
              })
            }

            if (payloadKey === SEARCH_TYPES.PRODUCT && payload[payloadKey].data[0] !== undefined) {
              payload[payloadKey].data[0].results.forEach((fields: any, index: number) => {
                if (index === 0) {
                  // push header
                  tempList.push({
                    title: 'Product header',
                    type: SEARCH_TYPES.PRODUCT,
                    selected: false,
                    header: true,
                  })
                }

                // push suggestion
                tempList.push({
                  title: fields.name.snippet !== null ? fields.name.snippet : fields.name.raw,
                  type: SEARCH_TYPES.PRODUCT,
                  selected: false,
                  id: fields._meta.id,
                  url: fields.clickurl.raw,
                  fields,
                })
              })
            }

            if (payloadKey === SEARCH_TYPES.CATEGORIES && payload[payloadKey].data[1] !== undefined) {
              payload[payloadKey].data[1].facets.categories[0].data.forEach((fields: any, index: number) => {
                if (index === 0) {
                  // push header
                  tempList.push({
                    title: 'Product Categories',
                    total: payload[payloadKey].data[1].meta.page.total_results,
                    type: SEARCH_TYPES.CATEGORIES,
                    selected: false,
                    header: true,
                  })
                }

                // push suggestion
                tempList.push({
                  title: fields.value,
                  count: fields.count,
                  type: SEARCH_TYPES.CATEGORIES,
                  selected: false,
                  fields,
                })
              })
            }

            if (payloadKey === SEARCH_TYPES.RESOURCE) {
              payload[payloadKey].data.results.forEach((fields: any, index: number) => {
                // If these fields are missing, skip this stack
                if (!fields.headings && !fields.title) {
                  return
                }

                if (index === 0) {
                  // push header
                  tempList.push({
                    title: 'Resources header',
                    type: SEARCH_TYPES.RESOURCE,
                    selected: false,
                    header: true,
                  })
                }

                // push suggestion
                // find the title
                const contentTitle = getResourceHeading(fields)
                if (contentTitle) {
                  tempList.push({
                    title: highlightText(this.searchInput, contentTitle),
                    type: SEARCH_TYPES.RESOURCE,
                    selected: false,
                    id: fields._meta.id,
                    url: fields.url.raw,
                    fields,
                  })
                }
              })
            }

            if (payloadKey === SEARCH_TYPES.SUGGESTIONS) {
              payload[payloadKey].data.results.documents.forEach((suggestion: any, index: number) => {
                if (index === 0) {
                  // push header
                  tempList.push({
                    title: 'Suggestions',
                    type: SEARCH_TYPES.SUGGESTIONS,
                    selected: false,
                    header: true,
                  })
                }

                // push suggestion
                tempList.push({
                  title: highlightText(this.searchInput, suggestion.suggestion),
                  type: SEARCH_TYPES.SUGGESTIONS,
                  selected: false,
                })
              })
            }
          }
        })

      // Set the list
      this.typeahead.suggestionList = tempList

      // If no suggestions, close the suggestion box
      if (isEmpty(tempList)) {
        this.typeahead.showSuggests = false
        this.typeahead.showHistoryOnly = true
      }
    },
    clearSuggestSelected() {
      this.typeahead.suggestionList.forEach((el: SuggestionlistObjectInterface) => {
        el.selected = false
      })
    },
    clearSearch() {
      this.hideSuggests()
      this.clearSuggestSelected()
      this.resetSuggests()
      this.searchInput = ''
    },
    closeAllInfoPanels() {
      this.infoPanelOpenIDs = []
      this.infoPanelsOpen = false
    },
    setSuggestSelected(oldIndex: number | null, newIndex: number | null) {
      // remove selected
      // only if new and old index are not the same
      if (oldIndex !== null) {
        if (this.typeahead.suggestionList[oldIndex] !== undefined) {
          this.typeahead.suggestionList[oldIndex].selected = false
        }
      }

      // add selected
      if (newIndex !== null) {
        if (this.typeahead.suggestionList[newIndex] !== undefined) {
          this.typeahead.suggestionList[newIndex].selected = true
        }
      }
    },
    deleteSuggest(type: string, key: string = '') {
      // If no value, delete everything from this type
      if (isEmpty(key)) {
        this.typeahead.suggestionList = this.typeahead.suggestionList.filter((el: SuggestionlistObjectInterface) => {
          if (el.type !== type) {
            return el
          }
          return false
        })
      } else {
        // delete one item from array
        const index = this.typeahead.suggestionList.findIndex(
          (el: SuggestionlistObjectInterface) => el.type === type && el.title === key
        )

        if (index !== -1) {
          this.typeahead.suggestionList.splice(index, 1)
        }
      }
    },
    setSearchInput(val: string) {
      // normalize for japanse language input
      this.searchInput = sanitizeString(stripHTMLTags(decodeURIComponent(val)))
        .normalize('NFKC')
        .trim()
    },
    setTypedSearchQuery(val: string) {
      this.typedSearchQuery = sanitizeString(stripHTMLTags(decodeURIComponent(val)))
        .normalize('NFKC')
        .trim()
    },
    setQueryStyle(queryStyle: QueryStyle) {
      this.queryStyle = queryStyle
    },
    setFacets(facets: any) {
      // patch the facet prop
      this.$patch((state) => {
        state.results.facets = facets
      })
    },
    setCheckFacets(checkedFacets: Array<CheckedFacetsInterface>) {
      this.facets.checkedFacets = checkedFacets
    },
    deleteCheckedFacets() {
      this.facets.checkedFacets = []
    },
    deleteClickedMultipleFacets() {
      this.facets.clickedMultiselectFacet = {
        facetname: '',
        facetdata: [],
      }
    },
    changeLoader(boolean: boolean) {
      this.ui.showLoader = boolean
    },
    toggleShowMobileFacets() {
      this.ui.showMobileFacets = !this.ui.showMobileFacets
    },
  },
  // getters
  getters: {
    getSearchType: (state) => {
      return state.tabs.selectedTab === SEARCH_TYPES.PRODUCT ? 'products' : 'resources'
    },
    hasProductResults: (state) => {
      if (state.results[SEARCH_TYPES.PRODUCT].results !== undefined) {
        return state.results[SEARCH_TYPES.PRODUCT].results!.length > 0
      } else {
        return false
      }
    },
    hasResourcesResults({ results }): boolean {
      if (results[SEARCH_TYPES.RESOURCE].results !== undefined) {
        return results[SEARCH_TYPES.RESOURCE].results!.length > 0
      } else {
        return false
      }
    },
    isProductTab({ tabs }): boolean {
      return tabs.selectedTab === SEARCH_TYPES.PRODUCT
    },
    isResourcesTab({ tabs }): boolean {
      return tabs.selectedTab === SEARCH_TYPES.RESOURCE
    },
    getResults({ results, tabs }): SearchResultsObjectInterface {
      return results[tabs.selectedTab]
    },
    getAlternativeTab:
      ({ tabs }) =>
      () => {
        if (tabs.selectedTab === SEARCH_TYPES.PRODUCT) {
          return SEARCH_TYPES.RESOURCE
        } else {
          return SEARCH_TYPES.PRODUCT
        }
      },
    getProductListPosition: (state) => (id: string) => {
      const index = state.results[state.tabs.selectedTab].results?.findIndex(
        (el) => el.productnumber.raw === id.toString()
      )
      if (index !== undefined) {
        return index + 1
      } else {
        return null
      }
    },
    getSortField: (state) => {
      // For content, always set the field to relevance
      if (state.tabs.selectedTab === SEARCH_TYPES.RESOURCE) {
        state.page.sortField = 'Relevance'
      }

      return sortMapping[state.page.sortField as keyof typeof sortMapping]
    },
    getOrder: (state) => {
      // relevance is based on a score. ASC means lowest score first. But people expect with ASC the highest score first
      // so only for Relevance, reverse the order
      if (
        state.page.sortField === 'Relevance' ||
        state.page.sortField === 'Newest' ||
        state.page.sortField === '# of Citations' ||
        state.page.sortField === '# of Applications' ||
        state.tabs.selectedTab === SEARCH_TYPES.RESOURCE
      ) {
        return 'desc'
      } else {
        return 'asc'
      }
    },
    getTotalResults: (state) => {
      return state.results[state.tabs.selectedTab]?.meta?.page?.total_results || 0
    },
    // getResultById: (state) => (id : number) => {
    //   let singleItem = []
    //   // check in results
    //   if(state.results.hits !== undefined || state.results.results !== undefined){
    //       if(state.searchvendor == 'algolia'){
    //           singleItem = state.results.hits.find(result => result.objectID === id)
    //       } else if(state.searchvendor == "elasticsearch"){
    //           singleItem = state.results.results.find(result => result.data._meta.id === id)
    //       } else {
    //           singleItem = state.results.hits.hit.find(result => result.id === id)
    //       }
    //   }
    //   // if no hit, check in sugestions
    //   if(singleItem == undefined || singleItem.length == 0){
    //      if(state.typeahead.suggests.length > 0){
    //           for (const suggestColumn of state.typeahead.suggests) {
    //               let foundSuggestion = ''
    //               if(suggestColumn.data.length > 0){
    //                   // ALgolia
    //                   if(state.searchvendor == 'algolia'){
    //                       foundSuggestion = suggestColumn.data.find(suggestItem => {
    //                           if(suggestItem.objectID !== undefined){
    //                               return suggestItem.objectID === id
    //                           }
    //                       })
    //                   }
    //                   // Other vendor
    //                   else {
    //                       foundSuggestion = suggestColumn.data.hit.find(suggestItem => suggestItem.id === id)
    //                   }
    //               }
    //               if(foundSuggestion !== undefined){
    //                   singleItem = foundSuggestion
    //                   break
    //               } else {
    //                   return ''
    //               }
    //           }
    //      }
    //   }
    //   return singleItem
    // }
  },
})
