





















































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import ZoomImageModal from './ZoomImageModal.vue'

export default defineComponent({
  components: { ZoomImageModal },
  props: {
    variant: {
      type: String as PropType<'fifty-fifty' | 'caption-grow' | 'img-only'>,
      default: 'fifty-fifty',
    },
    modalTitle: {
      type: String,
      default: '',
    },
    showCaptionInModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const variantFiftyFifty = computed(() => props.variant === 'fifty-fifty')
    const variantImageOnly = computed(() => props.variant === 'img-only')
    const variantCaptionGrow = computed(() => props.variant === 'caption-grow')

    const invalidVariant = computed(() => !variantFiftyFifty && !variantImageOnly && !variantCaptionGrow)

    return { variantFiftyFifty, variantImageOnly, variantCaptionGrow, invalidVariant }
  },
})
