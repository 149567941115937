











import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    local: {
      type: Boolean,
      require: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
  },
})
