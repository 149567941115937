import uniq from 'lodash/fp/uniq'
import {
  SignalstarAntibody,
  SignalstarAssignedAntibody,
  GroupedSignalstarAntibodies,
} from '@cstweb/commercetools-client'
import { PanelBuilderConfigurationV2 } from '~/store/panel-builder'

// ..
export const PANEL_BUILDER_COOKIE = 'panel-builder'
export const SELECTION_LIMIT = 8

// ..
export const getRounds = (array: SignalstarAntibody[]) => {
  const arrayCopy = [...array]
  const sortedByImagingRoundsLength = arrayCopy.sort((a, b) =>
    Object.keys(a.imagingRounds).length <= Object.keys(b).length ? -1 : 1
  )

  // .. We should find the item with longest number of rounds
  const longestImagingRounds = sortedByImagingRoundsLength?.[0]?.imagingRounds
  return Object.keys(longestImagingRounds ?? {}).sort((a, b) => (a <= b ? -1 : 1))
}
export const getColors = (array: SignalstarAntibody[]) => {
  const arrayCopy = [...array]
  const sortedByTypesLength = arrayCopy.sort((a, b) => (a.types.length <= b.types.length ? -1 : 1))

  // .. We should find the item with longest array of types
  const longestTypes = sortedByTypesLength?.[0]?.types
  return [...new Set(longestTypes?.map((color) => color.name).sort((a, b) => (a <= b ? -1 : 1)))]
}

// ..
export const sortByRounds = (array: SignalstarAssignedAntibody[]) => {
  return array.sort((prev, curr) => (prev.round <= curr.round ? -1 : 1))
}

// ..
export const groupByRounds = (
  array: SignalstarAntibody[],
  rounds: string[],
  expectedRoundValue: boolean = true
): GroupedSignalstarAntibodies =>
  rounds.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: array.filter((anti) => anti.imagingRounds[curr] === expectedRoundValue),
    }),
    {}
  )

export const groupByColors = (
  array: SignalstarAntibody[],
  colors: string[],
  expectedColorValue: boolean = true
): GroupedSignalstarAntibodies =>
  colors.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: array.filter((anti) =>
        anti.types?.filter((type) => type.name === curr && type.imaging === expectedColorValue).pop()
      ),
    }),
    {}
  )

// ..
export const uniqueItems = (
  allGrouped: GroupedSignalstarAntibodies,
  selectedGrouped: GroupedSignalstarAntibodies,
  currGroup: string
) => {
  const flatGroupedArray = Object.values(allGrouped).flat()
  return selectedGrouped[currGroup].reduce(
    // .. If item is present in more than once it is not unique item
    (prev: SignalstarAntibody[], currItem) =>
      flatGroupedArray.filter((item) => item === currItem).length === 1 ? [...prev, currItem] : prev,
    []
  )
}

export const getAvailableItems = (allAntibodies: SignalstarAntibody[], selectedTargets: SignalstarAntibody[]) => {
  const rounds = getRounds(allAntibodies)
  const colors = getColors(allAntibodies)

  const allGroupedByRounds = groupByRounds(allAntibodies, rounds)
  const selectedGroupedByRounds = groupByRounds(selectedTargets, rounds)

  // .. First start verifying rounds
  const availableItems = rounds.reduce((prevRoundArray: SignalstarAntibody[], currRound) => {
    const numberOfUniqueProductsPerRound = uniqueItems(allGroupedByRounds, selectedGroupedByRounds, currRound).length

    // .. Round should contain up to 4 unique-round items, others can be universal
    if (!(numberOfUniqueProductsPerRound < colors.length)) {
      return prevRoundArray
    }

    // .. Continue with dividing items per round by colors
    const availablePerRoundGroupedByColors = groupByColors(uniq(allGroupedByRounds[currRound]), colors)
    const availablePerRoundGroupedByMissingColors = groupByColors(uniq(allGroupedByRounds[currRound]), colors, false)
    const selectedPerRoundGroupedByColors = groupByColors(selectedGroupedByRounds[currRound], colors)
    const selectedPerRoundGroupedByMissingColors = groupByColors(selectedGroupedByRounds[currRound], colors, false)

    // .. Verify colors, build array of available colors
    const availableByColors = colors.reduce((prevColorArray: SignalstarAntibody[], currColor: string) => {
      const numberOfUniqueProductsPerColor = uniqueItems(
        availablePerRoundGroupedByColors,
        selectedPerRoundGroupedByColors,
        currColor
      ).length

      // .. Handle scenario if product missing 750 color
      if (selectedPerRoundGroupedByMissingColors[currColor].length >= colors.length - 1) {
        prevColorArray = prevColorArray.filter(
          (item) => !availablePerRoundGroupedByMissingColors[currColor].includes(item)
        )
      }

      // .. Color is limited to only one unique-color item per round
      return [
        ...prevColorArray,
        ...(numberOfUniqueProductsPerColor < 1 ? availablePerRoundGroupedByColors[currColor] : []),
      ]
    }, [])

    // .. Return of available items
    return [...prevRoundArray, ...uniq(availableByColors)]
  }, [] as SignalstarAntibody[])

  // .. Final return
  return uniq([...selectedTargets, ...availableItems])
}

type RequiredField<T, K extends keyof T> = T & Required<Pick<T, K>>

// .. SS-TODO: correct typings
export type StepTwoConfiguration = RequiredField<PanelBuilderConfigurationV2, 'targetSpecies'>
export function isStepTwoReady(
  configuration: PanelBuilderConfigurationV2 | null
): configuration is StepTwoConfiguration {
  return !!configuration?.targetSpecies
}

export type StepThreeConfiguration = RequiredField<StepTwoConfiguration, 'selectedTargets'>
export function isStepThreeReady(
  configuration: PanelBuilderConfigurationV2 | null
): configuration is StepThreeConfiguration {
  return isStepTwoReady(configuration) && (configuration.selectedTargets?.length || 0) >= 3
}

export type StepFourConfiguration = RequiredField<StepThreeConfiguration, 'imagingRounds' | 'bufferKit'>
export function isStepFourReady(
  configuration: PanelBuilderConfigurationV2 | null
): configuration is StepFourConfiguration {
  return isStepThreeReady(configuration) && !!configuration.imagingRounds?.length && !!configuration.bufferKit
}
