var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.variantFiftyFifty ? 'tw-pb-[3.25rem]' : 'tw-pb-[1.5rem]', _vm.variantImageOnly ? 'tw-pt-0' : 'tw-pt-[1.5rem]']},[_c('section',{staticClass:"tw-flex tw-w-full tw-flex-col tw-space-y-1 lg:tw-flex-row lg:tw-space-y-0"},[_c('div',{staticClass:"tw-w-2/3 tw-px-4",class:{
        'lg:tw-w-1/2': _vm.variantFiftyFifty,
        'tw--ml-4 lg:tw-w-[max(25%_,_10rem)]': _vm.variantCaptionGrow,
        'lg:tw-w-full': _vm.variantImageOnly || _vm.invalidVariant,
        'tw-mx-auto sm:tw-mx-0': !_vm.variantCaptionGrow,
      }},[_c('div',{staticClass:"tw-relative tw-flex tw-cursor-pointer tw-justify-center tw-rounded tw-border tw-border-dusty-gray-200",class:_vm.variantCaptionGrow ? 'tw-p-1' : 'tw-p-4',on:{"click":function($event){return _vm.$modal.show(_vm.modalTitle)}}},[_vm._t("contentImage"),_vm._v(" "),_c('i',{staticClass:"cstf cstf-search-plus icon-inner-shadow icon-big tw-absolute tw-bottom-2 tw-right-2 tw-text-white"})],2)]),_vm._v(" "),(!_vm.variantImageOnly)?_c('div',{staticClass:"tw-w-full tw-px-4",class:{
        'lg:tw-w-1/2': _vm.variantFiftyFifty,
        'tw--ml-4 lg:tw-w-[calc(100%_-_max(25%_,_10rem))]': _vm.variantCaptionGrow,
        'lg:tw-hidden': _vm.variantImageOnly,
        'lg:tw-w-full': _vm.invalidVariant,
      }},[_vm._t("caption")],2):_vm._e()]),_vm._v(" "),_c('ZoomImageModal',{attrs:{"modal-title":_vm.modalTitle},scopedSlots:_vm._u([{key:"contentImage",fn:function(){return [_vm._t("contentImage")]},proxy:true},(_vm.showCaptionInModal)?{key:"caption",fn:function(){return [_vm._t("caption")]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }