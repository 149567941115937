






























import { defineComponent, useMeta } from '@nuxtjs/composition-api'
import { PropType } from 'vue-demi'

export default defineComponent({
  props: {
    videoId: {
      type: String,
      required: true,
    },
    position: {
      type: [String, Object] as PropType<{ width: string; height: string } | 'responsive'>,
      default: 'responsive',
    },
    isPopover: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    useMeta({
      script: [
        {
          src: 'https://fast.wistia.com/assets/external/E-v1.js',
          async: true,
          hid: 'wistia-external-script',
        },
      ],
    })
  },
  head: {},
})
