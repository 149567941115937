






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      required: false,
      default: 'fit-content',
    },
  },
  setup(props) {
    const btnClick = (): void => {
      // perform redirect
      if (props.href) {
        window.location.href = props.href
      }
    }

    const mappedWidthValue = {
      'fit-content': 'tw-w-auto',
      '100%': 'tw-w-full',
      '50%': 'tw-w-1/2',
    }

    const widthClass = mappedWidthValue[props.width as keyof typeof mappedWidthValue] || ('tw-w-auto' as string)

    return {
      btnClick,
      widthClass,
    }
  },
})
