







import { defineComponent, useMeta } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    useMeta({
      script: [
        {
          src: 'https://fast.wistia.com/assets/external/channel.js',
          async: true,
          hid: 'wistia-external-script',
        },
      ],
      link: [
        {
          rel: 'stylesheet',
          href: `https://fast.wistia.com/embed/channel/project/${props.videoId}/font.css`,
        },
      ],
    })
  },
  head: {},
})
