import { TreeLeaf, RteNode } from '~/types/rte/rteTypes'

// Method to check if a json RTE field is empty
export const isEmptyJsonRteField = (json: RteNode) => {
  return !json?.children?.some((child: RteNode) => {
    // Recursive check to dive tree structure
    if (child?.children) {
      return !isEmptyJsonRteField(child)
    }

    // Any text is means that rte is not empty
    if ((child as TreeLeaf)?.text?.length > 0) {
      return true
    }

    // Elements which might not include text means that rte is not empty
    const elementsWithoutTexts = ['reference', 'img'] as const
    if (elementsWithoutTexts.includes(child?.type)) {
      return true
    }

    return false
  })
}

export const hasDocEmptyTexts = (obj: any) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!hasDocEmptyTexts(obj[key])) {
          return false
        }
      } else if (key === 'text' && obj[key] !== '') {
        return false
      }
    }
  }
  return true
}
