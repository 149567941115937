







import { defineComponent } from '@nuxtjs/composition-api'
import { isEmptyJsonRteField } from '~/common/utils/static-content/cms'
import { RteNode } from '~/types/rte/rteTypes'

export default defineComponent({
  name: 'ContentPageTable',
  components: {
    // dynamic import because of circular reference in JsonRteField component
    JsonRteField: () => import('~/components/global/cms/JsonRteField.vue'),
  },
  props: {
    data: {
      required: true,
      type: Object as () => { html_table: string; title: string; heading: { json: RteNode } },
    },
  },
  setup(props) {
    const table = props.data.html_table
    const heading = props.data?.heading
    return { table, heading, isEmptyJsonRteField }
  },
})
