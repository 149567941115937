// Pinia
import { defineStore } from 'pinia'

import { AddressPayload, MethodValue } from '@cstweb/commercetools-client'
import { useCheckoutStore } from './checkout'
import { useUserStore } from './user'
import {
  parseAddressesResponse,
  parseOrderResponse,
  parseQuoteResponse,
  userInfoPayloadFactory,
} from '~/common/utils/parsing'

// Types
import { OrderInterface, PriceInterface } from '@/types/profile/orderHistoryTypes'

import {
  ProfileAddressesInterface,
  ProfileStoreInterface,
  ProfileContactsInterface,
  ProfileUserInfoInterface,
  Status,
  LabNotebookInterface,
} from '@/types/profile/profileTypes'
import useQuotes from '~/composables/quotes/useQuotes'

export const useProfileStore = defineStore({
  id: 'profile',
  state: (): ProfileStoreInterface => ({
    userInfo: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      company: '',
      mobile: '',
      mobileExt: '',
      vatId: '',
      ebsSoldToIds: [],
      emailDomain: '',
      migrationStatus: '',
      legacyId: '',
      registrationDate: '',
    },
    isVerified: false,
    addresses: [],
    shippingAddressIds: [],
    billingAddressIds: [],
    defaultShippingAddressId: '',
    defaultBillingAddressId: '',
    defaultContactId: '',
    contacts: [],
    labNotebooks: [],
    orderHistory: {
      limit: 10,
      offset: 0,
      count: 0,
      total: 0,
      results: [],
    },
    currentOrderDetails: null,
    myQuotes: {
      limit: 10,
      offset: 0,
      count: 0,
      total: 0,
      results: [],
    },
    isLoading: false,
    isExpanded: false,
    isPunchout: false,
    punchoutWelcomeMsg: '',
  }),
  actions: {
    // Init
    async fetchProfile() {
      try {
        this.isLoading = true
        const { data } = await this.$nuxt.$ct.customer.info()
        this.setProfile(data, true)
        return data
      } finally {
        this.isLoading = false
      }
    },

    setProfile(customer: any, isExpanded: boolean) {
      this.$patch({
        userInfo: {
          id: customer.id,
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email,
          company: customer.companyName,
          country: customer?.custom?.fields?.registrationOrigin ?? '',
          mobile: customer?.custom?.fields?.mobilePhone ?? '',
          mobileExt: customer?.custom?.fields?.mobilePhoneExtension ?? '',
          vatId: customer?.vatId,
          emailDomain: customer?.custom?.fields?.emailDomain ?? '',
          migrationStatus: customer?.custom?.fields?.migrationStatus ?? '',
          legacyId: customer?.custom?.fields?.legacyId ?? '',
          registrationDate: customer?.custom?.fields?.registrationDate ?? '',
          ebsSoldToIds: customer?.custom?.fields?.ebsSoldToIds ?? [],
        },
        isVerified: customer.isEmailVerified,
        addresses: parseAddressesResponse(customer.addresses),
        shippingAddressIds: customer.shippingAddressIds,
        billingAddressIds: customer.billingAddressIds,
        defaultShippingAddressId: customer.defaultShippingAddressId ?? '',
        defaultBillingAddressId: customer.defaultBillingAddressId ?? '',
        defaultContactId: this.getMyselfContactId(customer?.custom?.fields?.contacts?.obj?.value?.contacts),
        contacts: customer?.custom?.fields?.contacts?.obj?.value?.contacts ?? [],
        isExpanded,
        isPunchout: customer.custom.fields?.isPunchout ?? false,
        punchoutWelcomeMsg: customer.custom.fields?.welcomeMessage ?? '',
      })
    },

    async fetchLabNotebooks() {
      this.isLoading = true

      await this.$nuxt.$ct.customer
        .getLabNotebooks()
        .then((res: { data: LabNotebookInterface[] }) => {
          this.$patch({ labNotebooks: res.data })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async fetchOrderHistory(offset: number = 0, limit: number = 10) {
      this.isLoading = true

      try {
        const res = await this.$nuxt.$ct.customer.getOrderHistory(offset, limit)
        this.$patch({
          orderHistory: {
            limit: res.data.limit,
            offset: res.data.offset,
            count: res.data.count,
            total: res.data.total,
            results: res.data.results?.map((order: any) => parseOrderResponse(order, useUserStore().selectedLocale)),
          },
        })
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.isLoading = false
      }

      return this.orderHistory
    },

    /**
     * Function for fetching and patching `currentOrderDetail` used mainly on
     * _ordernumber page.
     *
     * @param orderNumber as string, number of the order
     * @returns
     */

    async fetchOrderByOrderNumber(orderNumber: string): Promise<OrderInterface | null> {
      this.isLoading = true

      try {
        // .. First search for already present order
        const alreadyFetchedOrder = this.orderHistory.results.find((order) => order.orderNumber === orderNumber)
        if (alreadyFetchedOrder) {
          // .. Patch & return
          this.currentOrderDetails = alreadyFetchedOrder
          return alreadyFetchedOrder
        }

        // .. If not fetch order by product number and patch it to store
        const res = await this.$nuxt.$ct.customer.getOrderByOrderNumber(orderNumber)
        const fetchedParsedOrder = res.data.results.length
          ? parseOrderResponse(res.data.results[0], useUserStore().selectedLocale)
          : null

        this.currentOrderDetails = fetchedParsedOrder
        return fetchedParsedOrder
      } finally {
        this.isLoading = false
      }
    },

    async fetchMyQuotes(limit: number, offset: number) {
      this.isLoading = true

      try {
        const res = await useQuotes(this.$nuxt).fetchMyQuotes({ limit, offset })
        this.$patch({
          myQuotes: {
            ...res,
            results: res.results.map((quote) => parseQuoteResponse(quote)),
          },
        })
      } finally {
        this.isLoading = false
      }

      return this.myQuotes
    },

    // Add
    async addAddress(address: AddressPayload, { billing, shipping }: { billing?: boolean; shipping?: boolean } = {}) {
      let status: Status = 'loading'

      await this.$nuxt.$ct.customer.addAddress(address).then(async (res: any) => {
        if (billing) {
          await this.setBillingAddress(res.data.addresses.at(-1)?.id ?? '', true)
        }
        if (shipping) {
          await this.setShippingAddress(res.data.addresses.at(-1)?.id ?? '', true)
        }
        this.$patch({
          addresses: parseAddressesResponse(res.data.addresses),
        })

        status = 'success'
      })

      return status as Status
    },

    async addContact(contact: any) {
      let status: Status = 'loading'

      await this.$nuxt.$ct.customer.addContact(contact).then((res: any) => {
        this.$patch({
          contacts: res.data.contacts,
        })

        status = 'success'
      })

      return status as Status
    },

    // Update
    async updateUserInfo(newUserInfo: ProfileUserInfoInterface) {
      this.isLoading = true

      await this.$nuxt.$ct.customer
        .updateUserInfo(userInfoPayloadFactory(this.userInfo, newUserInfo))
        .then((res: any) => {
          this.$patch({
            userInfo: {
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              company: res.data.companyName,
              vatId: res.data?.vatId,
              mobile: res.data?.custom?.fields?.mobilePhone ?? '',
              mobileExt: res.data?.custom?.fields?.mobilePhoneExtension ?? '',
              emailDomain: res.data?.custom?.fields?.emailDomain ?? '',
              migrationStatus: res.data?.custom?.fields?.migrationStatus ?? '',
              legacyId: res.data?.custom?.fields?.legacyId ?? '',
              registrationDate: res.data?.custom?.fields?.registrationDate ?? '',
              ebsSoldToIds: res.data?.custom?.fields?.ebsSoldToIds ?? [],
            },
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async updateContact(contactId: string, contact: any) {
      let status: Status = 'loading'

      await this.$nuxt.$ct.customer.updateContact(contactId, contact).then((res: any) => {
        this.$patch({
          contacts: res.data.contacts,
        })

        status = 'success'
      })

      return status as Status
    },

    async updateAddress(addressId: string, address: AddressPayload) {
      let status: Status = 'loading'

      await this.$nuxt.$ct.customer.updateAddress(addressId, address).then((res: any) => {
        this.$patch({
          addresses: parseAddressesResponse(res.data.addresses),
        })

        status = 'success'
      })

      return status as Status
    },

    // Remove
    async deleteAddress(id: string) {
      this.isLoading = true
      try {
        const checkoutStore = useCheckoutStore()
        const { data } = await this.$nuxt.$ct.customer.removeAddress(id)
        this.$patch({
          addresses: parseAddressesResponse(data.addresses),
          // In case user deletes shipping/billing/default address
          shippingAddressIds: data.shippingAddressIds,
          billingAddressIds: data.billingAddressIds,
          defaultShippingAddressId: data.defaultShippingAddressId ?? '',
          defaultBillingAddressId: data.defaultBillingAddressId ?? '',
        })
        if (
          checkoutStore.basketItems.data.shippingAddress?.id === id ||
          checkoutStore.basketItems.data.billingAddress?.id === id
        ) {
          checkoutStore.setSelectedShippingAddress(data.defaultShippingAddressId ?? '')
          checkoutStore.setSelectedBillingAddress(data.defaultBillingAddressId ?? '')
          const res = await this.$nuxt.$ct.cart.syncCartAfterAddressOrContactDeletion('address')
          checkoutStore._updateStore(res.data)
        }
      } finally {
        this.isLoading = false
      }
    },

    async deleteContact(id: string) {
      let status: Status = 'loading'
      try {
        const checkoutStore = useCheckoutStore()
        const { data } = await this.$nuxt.$ct.customer.removeContact(id)
        this.$patch({
          defaultContactId: this.getMyselfContactId(data.contacts),
          contacts: data.contacts,
        })
        if (checkoutStore.basketItems.data.custom?.fields.contactId === id) {
          checkoutStore.setSelectedContact(this.defaultContactId ?? '')
          const res = await this.$nuxt.$ct.cart.syncCartAfterAddressOrContactDeletion('contact')
          checkoutStore._updateStore(res.data)
        }
      } finally {
        status = 'success'
      }
      return status as Status
    },

    // Set
    async setShippingAddress(id: string, value: boolean) {
      await this.$nuxt.$ct.customer.setShippingAddress(id, value).then((res: any) => {
        // ..
        this.$patch({
          shippingAddressIds: res.data.shippingAddressIds,
          defaultShippingAddressId: res.data.defaultShippingAddressId ?? '',
        })
      })
    },

    async setBillingAddress(id: string, value: boolean) {
      await this.$nuxt.$ct.customer.setBillingAddress(id, value).then((res: any) => {
        // ..
        this.$patch({
          billingAddressIds: res.data.billingAddressIds,
          defaultBillingAddressId: res.data.defaultBillingAddressId ?? '',
        })
      })
    },

    async setDefaultShippingAddress(id: string) {
      await this.$nuxt.$ct.customer.setDefaultShippingAddress(id).then((res: any) => {
        // ..
        this.$patch({
          defaultShippingAddressId: res.data.defaultShippingAddressId,
        })

        useCheckoutStore().setSelectedShippingAddress(this.defaultShippingAddressId)
      })
    },

    async setDefaultBillingAddress(id: string) {
      await this.$nuxt.$ct.customer.setDefaultBillingAddress(id).then((res: any) => {
        // ..
        this.$patch({
          defaultBillingAddressId: res.data.defaultBillingAddressId,
        })

        useCheckoutStore().setSelectedBillingAddress(this.defaultBillingAddressId)
      })
    },

    async createPayment(totalPrice: PriceInterface, method: string, methodValue: MethodValue) {
      this.isLoading = true
      try {
        const { data } = await this.$nuxt.$ct.customer.createPayment(totalPrice, method, methodValue)
        return data
      } finally {
        this.isLoading = false
      }
    },
  },
  getters: {
    getDefaultShippingAddress: (state) => (): ProfileAddressesInterface | null => {
      if (state.defaultShippingAddressId !== '') {
        return state.addresses.filter((address) => address.id === state.defaultShippingAddressId)[0] ?? null
      }

      // TODO: Handle default state if no shipping address
      // this should be the default state
      // if (state.shippingAddressIds) {
      //   return state.addresses.filter((address) => address.id === state.shippingAddressIds[0])[0] ?? null
      // }
      // return state.addresses[0]

      return null
    },
    getDefaultBillingAddress: (state) => (): ProfileAddressesInterface | null => {
      if (state.defaultBillingAddressId !== '') {
        return state.addresses?.filter((address) => address.id === state.defaultBillingAddressId)[0] ?? null
      }

      // TODO: Handle default state if no billing address
      // this should be the default state
      // if (state.billingAddressIds) {
      //   return state.addresses.filter((address) => address.id === state.billingAddressIds[0])[0] ?? null
      // }
      // return state.addresses[0]
      return null
    },
    getShippingAddresses: (state) => () => {
      if (state.shippingAddressIds.length > 0) {
        const shippingAddresses: ProfileAddressesInterface[] = state.addresses?.filter((address) =>
          state.shippingAddressIds?.includes(address?.id)
        )

        return shippingAddresses
      }

      return []
    },
    getBillingAddresses: (state) => () => {
      if (state.billingAddressIds.length > 0) {
        const billingAddresses: ProfileAddressesInterface[] = state.addresses?.filter((address) =>
          state.billingAddressIds?.includes(address?.id)
        )

        return billingAddresses
      }

      return []
    },
    isAddressShipping:
      (state) =>
      (id: string): boolean =>
        state.shippingAddressIds.includes(id),
    isAddressBilling:
      (state) =>
      (id: string): boolean =>
        state.billingAddressIds.includes(id),
    getAddressById:
      (state) =>
      (id: string): ProfileAddressesInterface | null => {
        const address: ProfileAddressesInterface[] | undefined = state.addresses?.filter(
          (address: ProfileAddressesInterface) => {
            return address.id === id
          }
        )
        return address ? address[0] : null
      },
    getMyselfContactId:
      (_state) =>
      (contacts: ProfileContactsInterface[]): string | null => {
        return contacts?.filter((contact) => contact.isDefault)[0]?.id ?? null
      },
    getContactById:
      (state) =>
      (id: string): ProfileContactsInterface => {
        const contact: ProfileContactsInterface[] = state.contacts.filter((contact: ProfileContactsInterface) => {
          return contact.id === id
        })
        return contact[0]
      },

    fullName: (state) => `${state.userInfo.firstName} ${state.userInfo.lastName}`,
  },
})
