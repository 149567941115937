// Types
import { decode } from 'html-entities'
import { ElasticContentFieldTypeInterface } from '@/types/search/elasticTypes'

const stringOrUndefined = (input: string | null | undefined): string | undefined => {
  // .. Empty string & "undefined" & "null" will result in undefined
  if (!input || input.toLowerCase() === 'null' || input.toLowerCase() === 'undefined') {
    return undefined
  }

  return input
}

const firstCharUpperCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const allFirstCharUpperCase = (str: string) => {
  if (!str) return

  return str
    .toLowerCase()
    .split(' ')
    .map((word) => firstCharUpperCase(word))
    .join(' ')
}

const slugify = (text: string | undefined) => {
  if (text === undefined) {
    return
  }

  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
  const to = 'aaaaaeeeeeiiiiooooouuuunc------'

  const newText = text.split('').map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  return newText
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-y-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
}

const stripHTMLTags = (text: string) => {
  if (!text) {
    return text
  }
  return text.replace(/(<([^>]+)>)/gi, '')
}

const extractElasticFieldString = (input: ElasticContentFieldTypeInterface) => {
  return input.snippet ?? input.raw ?? ''
}

const decodeHTMLEntities = (input: string) => {
  if (input === undefined) {
    return
  }

  return decode(input).replaceAll('<sup>', '').replaceAll('</sup>', '')
}

const decodeHtml = (html: string) => {
  if (process.client) {
    const txt = document.createElement('textarea')
    txt.innerHTML = html
    return txt.value
  } else {
    return html
  }
}

const highlightText = (needle: string, haystack: string) => {
  if (needle === '') {
    return haystack
  }

  try {
    return haystack.replace(new RegExp(needle, 'gi'), (str) => `<strong>${str}</strong>`)
  } catch (e) {
    return haystack
  }
}

const getEmailDomain = (email: string) => {
  if (!email) {
    return undefined
  }
  return email.split('@')[1].replace(/\.[^/.]+$/, '')
}

const getTextExcerpt = (str: string, characterCount: number) =>
  str.length > characterCount ? str.substring(0, characterCount) + '...' : str

const removeSpecialCharacters = (str: string) => {
  return str.replace(/[^a-zA-Z0-9 \-%&]/g, '')
}

const copyToClipboard = (msg: string) => {
  if (process.client) {
    const textarea = document.createElement('textarea')
    textarea.value = msg

    document.body.appendChild(textarea)
    textarea.select()

    // .. Using deprecated command to support also older browsers
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }
}

const getSentences = (text: string, count: number = 2) => {
  if (text === undefined) {
    return ''
  }

  // Split the text into sentences based on the dot, question mark, and exclamation point + a space. Also, check if the next character is capitalized
  // use the backrefence in the replace function to keep the first matching group and add a |
  // Then split on this character
  const sentences = text.replace(/([.!?])+\s+(?=[<A-Z{1}])/g, '$1|').split('|')

  // If no sentences, just return the text
  if (sentences.length === 0) {
    return text
  }

  // If the sentences are less than the count, return the whole text
  if (sentences.length < count) {
    return text
  }

  // Return the first count sentences
  return sentences.slice(0, count).join(' ')
}

const sanitizeString = (str: string) => {
  // Replacing possible XSS marks in decoded or encoded state
  return str.replace(/(&#x3C;|&#x3E;|<|>|\/|&gt;|&lt;)+/g, '')
  // &#x3C; = < | &#x3E; = > | &gt = < | &lt; = >
}

export {
  copyToClipboard,
  firstCharUpperCase,
  allFirstCharUpperCase,
  slugify,
  stripHTMLTags,
  decodeHTMLEntities,
  extractElasticFieldString,
  decodeHtml,
  highlightText,
  getEmailDomain,
  getTextExcerpt,
  removeSpecialCharacters,
  getSentences,
  stringOrUndefined,
  sanitizeString,
}
