




import { defineComponent, onUnmounted, ref, useAsync, useContext, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    src: {
      required: true,
      type: String,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['svg-mounted', 'svg-unmounted'],
  setup(props, { emit }) {
    const svgRef = ref<Element | null>(null)
    const context = useContext()

    // TODO: Maybe replace with client side fetch + introduce skeleton while loading + emit loading
    const svg = useAsync(async () => {
      // .. Replace with error svg
      const errorSvg = '<p>Error happened when fetching svg!</p>'

      try {
        // .. In case of fetching from media server
        if (props.external) {
          const res = await context.$axios.get(props.src)
          return res.data
        }

        // .. In case of loading from local assets
        const svg = await import(`../../assets/images/${props.src}?raw`)
        return svg.default ?? errorSvg
      } catch (e) {
        console.log(e)
      }

      return errorSvg
    }, props.src)

    // .. This emits can be used for event listeners clean-ups
    watch(
      [svgRef, svg],
      () => {
        if (svg.value && svgRef.value) {
          /**
           * Have to use this timeout to wait for nextTick,
           * without this, elements would still not be rendered in dom,
           * even though the ref & svg value would be already present
           */
          setTimeout(() => {
            emit('svg-mounted', svgRef.value)
          }, 0)
        }
      },
      { immediate: true }
    )

    onUnmounted(() => {
      if (svgRef.value) {
        emit('svg-unmounted', svgRef.value)
      }
    })

    return {
      svg,
      svgRef,
    }
  },
})
