












import { defineComponent } from '@nuxtjs/composition-api'

// Components
import WistiaVideo from '~/components/feature/static-content/WistiaVideo.vue'
import WistiaCollection from '~/components/feature/static-content/WistiaCollection.vue'

// Types
import { ContentVideo } from '~/types/cms/video'

export default defineComponent({
  components: {
    WistiaVideo,
    WistiaCollection,
  },
  props: {
    data: {
      type: Object as () => ContentVideo,
      required: true,
    },
  },
})
