






import { defineComponent, ref, watch } from '@nuxtjs/composition-api'
import { useLoading } from '~/composables/loading/useLoading'

import { ButtonWidths } from '~/types/rte/rteTypes'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String as () => ButtonWidths,
      required: false,
      default: 'fit-content',
    },
  },
  setup(_, ctx) {
    const { isLoading } = useLoading()
    const disabledByLoading = ref(false)
    const state = ref<'active' | 'clicked' | 'loadingStarted' | 'loadingFinished'>('active')

    const listeners = {
      ...ctx.listeners,
      click: (...params: any[]) => {
        // intercept click event
        state.value = 'clicked'
        return ctx.listeners.click?.call(params)
      },
    }

    watch([state, isLoading], () => {
      if (state.value === 'clicked' && isLoading.value) {
        state.value = 'loadingStarted'
      } else if (state.value === 'loadingStarted' && !isLoading.value) {
        state.value = 'loadingFinished'
      }
    })

    watch(state, () => {
      if (state.value === 'active') {
        disabledByLoading.value = false
      } else if (state.value === 'clicked') {
        // do nothing
      } else if (state.value === 'loadingStarted') {
        disabledByLoading.value = true
      } else if (state.value === 'loadingFinished') {
        disabledByLoading.value = false
        state.value = 'active'
      }
    })

    return { isLoading, listeners, disabledByLoading }
  },
})
