import type { GlobalSettingsMediaNode, Link, Maybe, NavigationLinkLinkV2 } from '~/modules/contentstack/types'

export const normalizeLogo = (logo: Maybe<GlobalSettingsMediaNode>, link: NavigationLinkLinkV2) => {
  return {
    image: normalizeContentImage(logo),
    link,
  }
}

export const normalizeContentImage = (image: Maybe<GlobalSettingsMediaNode> | undefined) => {
  return {
    cloudflareId: image?.cloudflare_id,
    altText: image?.alt_text,
    caption: image?.caption,
    title: image?.image_fileConnection?.edges && image?.image_fileConnection?.edges[0]?.node?.title,
    description: image?.image_fileConnection?.edges && image?.image_fileConnection?.edges[0]?.node?.description,
    filename: image?.image_fileConnection?.edges && image?.image_fileConnection?.edges[0]?.node?.filename,
    url: image?.image_fileConnection?.edges && image?.image_fileConnection?.edges[0]?.node?.url,
    imageUrl: image ? generateImageUrl({ imageId: image?.cloudflare_id }) : '',
  }
}

export const generateImageUrl = ({ imageId = '' }: { imageId: Maybe<string> | undefined }) => {
  return `${imageId}/public`
}
