































import { defineComponent, ref } from '@nuxtjs/composition-api'
import MenuLink from '@/components/global/footer/MenuLink.vue'
import Social from '@/components/global/footer/Social.vue'
import { DEVKEY } from '~/common/constants/cms'
import { NavigationItem } from '~/types/cms/navigation'

export default defineComponent({
  components: { MenuLink, Social },
  props: {
    menuHolder: {
      type: Object as () => NavigationItem,
      required: true,
    },
    socials: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    techSupportNumber: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  setup() {
    const displayLinks = ref(false)
    const toggleLinks = () => {
      displayLinks.value = !displayLinks.value
    }
    return { displayLinks, toggleLinks, DEVKEY }
  },
})
