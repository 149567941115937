import {
  PUBMED_URL,
  SUPPORTING_DATA_ATTRIBUTES,
  SUPPORTING_DATA_LABELS,
  PRODUCT_INCLUDES_ATTRIBUTES,
  PRODUCT_INCLUDES_LABELS,
  applicationsWithOwnChiclet,
  APPLICATIONS,
  REACTANTS,
  PRODUCT_TYPES,
} from './constants'

import { SupportingDataAttribute } from '~/types/supportingData'
import { ProductIncludesAttribute } from '~/types/productIncludesData'

import {
  Application,
  ApplicationChiclets,
  KitComponent,
  MappedProduct,
  MappedSKU,
  ProductInterface,
  ProductReference,
  SpeciesCrossReactivity,
} from '~/types/product/productTypes'

export function filterReferencesForBackground(references: ProductReference[]) {
  return references.filter((reference) => reference.type.toLowerCase() !== 'application')
}

export function filterReferencesForManualCitations(references: ProductReference[]) {
  return references.filter((reference) => reference.type.toLowerCase() === 'application')
}

export function generateReferenceUrl(pubmedId: string) {
  return `${PUBMED_URL}/${pubmedId}`
}

export function mapSupportingDataAttributeToValue(
  productData: MappedProduct,
  attribute: SupportingDataAttribute,
  separator: string = ' '
) {
  switch (attribute) {
    case SUPPORTING_DATA_ATTRIBUTES.REACTIVITY: {
      return productData?.attributes.lots?.lot.reactivity?.reacts?.map((r: any) => r.code).join(separator)
    }
    case SUPPORTING_DATA_ATTRIBUTES.SENSITIVITY: {
      return productData?.attributes.lots?.lot.sensitivity
    }
    case SUPPORTING_DATA_ATTRIBUTES.OBSERVED_MOLECULAR_WEIGHT: {
      return productData?.attributes.observedMolecularWeight
    }
    case SUPPORTING_DATA_ATTRIBUTES.SOURCE: {
      return productData?.attributes.host
    }
    case SUPPORTING_DATA_ATTRIBUTES.ISOTYPE: {
      return `${productData?.attributes.host ?? ''} ${productData?.attributes.isotype ?? ''}`
    }
    case SUPPORTING_DATA_ATTRIBUTES.CONCENTRATION: {
      return productData?.attributes.lots?.lot?.concentration
    }
    // TODO: TBD when homology is added as an attribute, logic is the same as in reactivity
    // case SUPPORTING_DATA_ATTRIBUTES.HOMOLOGY:{
    //   return
    // }
  }
}

export function mapProductIncludesAttributeToValue(
  kitData: KitComponent[] | undefined,
  attributes: ProductIncludesAttribute[]
) {
  if (kitData) {
    const productIncludesTableData = {} as Record<
      string,
      { data: (string | undefined)[]; attributes: Record<string, string> }
    >

    kitData.forEach((component) => {
      const data = [] as (string | undefined)[]

      attributes.forEach((attribute) => {
        switch (attribute) {
          case PRODUCT_INCLUDES_ATTRIBUTES.ANNEALTEMP: {
            data.push(component.annealTemp)
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.APPLICATIONS: {
            const mappedApplicationCodes = component.lot?.applications?.application.map((a) => a.group) ?? []
            const uniqueApplicationCodes = Array.from(new Set(mappedApplicationCodes)).map((c) =>
              // Special case: `Dot Blot` should not be mapped
              c === APPLICATIONS.DOT_BLOT.group
                ? APPLICATIONS.DOT_BLOT.group
                : Object.values(APPLICATIONS).find((a) => a.group === c)?.codeForDisplay ?? c
            )
            data.push(uniqueApplicationCodes.join(', '))
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.CAPCOLOR: {
            data.push(component['cap-color'])
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.DILUTION: {
            data.push(component.dilution)
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.HOMOLOGY: {
            data.push(component.lot?.homology?.homologous.map((h) => h.code).join(', '))
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.ISOTYPE: {
            if (!component.host) {
              data.push(undefined)
              break
            }
            // String.fromCharCode(160) ~~> Non-breaking space
            data.push(`${component.host}${String.fromCharCode(160)}${component.isotype ?? ''}`)
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.MOLECULARWEIGHT: {
            data.push(component['molecular-weight-summary'])
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.PCRLENGTH: {
            data.push(component.pcrLength)
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.PRODUCTINCLUDES: {
            data.push(component.name + ' #' + component.number)
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.QUANTITY: {
            data.push(component.quantity + ' ' + component.unit)
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.QUANTITY_WITH_COUNT: {
            data.push(component.count + ' x ' + component.quantity + ' ' + component.unit)
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.REACTIVITY: {
            data.push(component.lot?.reactivity?.reacts.map((r) => r.code).join(' '))
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.SOLUTIONCOLOR: {
            data.push(component['solution-color'])
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.STORAGETEMP: {
            data.push(component['component-storage-temp']?.replace('C', '&#176;C'))
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.VOLUME: {
            data.push(component.quantity + ' ' + component.unit)
            break
          }
          case PRODUCT_INCLUDES_ATTRIBUTES.VOLUME_WITH_COUNT: {
            data.push(component.count + ' x ' + component.quantity + ' ' + component.unit)
            break
          }
          default: {
            data.push(undefined)
          }
        }
      })

      data.push(component.published)
      productIncludesTableData[component.number + ' '] = { data, attributes: { productUrl: component.sefUrl } }
    })

    return productIncludesTableData
  }
}

export function mapSupportingDataAttributeToLabel(attribute: SupportingDataAttribute) {
  const [key] = Object.entries(SUPPORTING_DATA_ATTRIBUTES).find(([_key, value]) => value === attribute)!
  return SUPPORTING_DATA_LABELS[key as keyof typeof SUPPORTING_DATA_LABELS]
}

export function mapProductIncludesAttributeToLabel(attributes: ProductIncludesAttribute[]): string[] {
  return attributes.map((attribute) => {
    const [key] = Object.entries(PRODUCT_INCLUDES_ATTRIBUTES).find(([_key, value]) => value === attribute)!
    return PRODUCT_INCLUDES_LABELS[key as keyof typeof PRODUCT_INCLUDES_LABELS]
  })
}

export function mapChicletsForApplicationTab(product: MappedProduct) {
  const chicletArray: ApplicationChiclets = []
  let includeOther = false

  product.attributes.lots?.lot.applications?.application.forEach((a: any) => {
    if ((a.figures || a.protocol) && a.codeForDisplay) {
      if (applicationsWithOwnChiclet.includes(a.codeForDisplay)) {
        if (!chicletArray.includes(a.codeForDisplay)) {
          chicletArray.push(a.codeForDisplay)
        }
      } else {
        includeOther = true
      }
    }
  })

  if (product.attributes.figures) {
    includeOther = true
  }

  if (includeOther) {
    chicletArray.push('other')
  }
  return chicletArray
}

export function getProductApplicationsForSupportingData(product: MappedProduct) {
  return mapChicletsForApplicationTab(product)
    .filter((val: string) => val !== 'other')
    .map((chiclet: string) => Object.values(APPLICATIONS).find((val: any) => chiclet === val.codeForDisplay))
}

export function getReactantsForSupportingData(product: MappedProduct) {
  return (
    mapSupportingDataAttributeToValue(product, 'reactivity', ',')
      ?.split(',')
      .map((reactant: string) => Object.values(REACTANTS).find((val: any) => reactant === val.codeForDisplay))
      .filter((x) => !!x) ?? []
  )
}

export function getProductStatus(product: ProductInterface) {
  return product.data.attributes.statusDates.status
}

export function filterSkuWithNonZeroPrice(skuData: MappedSKU[]) {
  return skuData.filter((sku) => sku.priceForCurrentCountry && sku.priceForCurrentCountry.centAmount > 0)
}

// TODO: Return only code and method not whole object
export function getApplicationsWithImagesForDatasheets(product: MappedProduct) {
  return mapChicletsForApplicationTab(product)
    .filter((val: string) => val !== 'other')
    .map((chiclet: string) =>
      product.attributes.lots?.lot.applications?.application.find((val: any) => chiclet === val.codeForDisplay)
    )
}

export function getEclipMessageFromApplications(applications: Application[] | undefined) {
  return applications?.find((application) => application.code === 'eCLIP')?.['application-message']
}

export function getDilutionsFromApplications(applications: Application[] | undefined) {
  return applications?.reduce((data: any, current: any) => {
    if (current['dilution-factor']?.optimal || current['dilution-factor']?.low) {
      data.push({
        application: current.method,
        dilution: current['dilution-factor']?.optimal
          ? current['dilution-factor']?.optimal.fraction
          : current['dilution-factor']?.low?.fraction + ' - ' + current['dilution-factor']?.high?.fraction,
      })
    }
    return data
  }, [] as Object[])
}

export function getAllSpecies(species: SpeciesCrossReactivity[] | undefined) {
  return species?.map((val) => val.species).join(', ')
}

export function extractProductIdFromSku(sku: string) {
  return sku.match(/\d{4,}/gm)?.[0]
}

export function isSignalStarSecondaryAntibodyKit(product: MappedProduct) {
  return (
    product.productType === PRODUCT_TYPES.OLIGO_ANTIBODY_PAIR && product.name.includes(PRODUCT_TYPES.SECONDARY_ANTIBODY)
  )
}
